<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { downloadAction, getAction } from '@/command/netTool'

export default {
  name: 'trade-statistics',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getDsReportList',
      params: { current: 1, pageSize: 10 },
      paramsValue: { host: '/api' },
      shTypeList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(params = this.params) {
      getAction('/merchantTransaction/reportPage', params).then((e) => {
        this.params = params
        let data = e.data.records
        this.current = e.data['current']
        this.total = e.data['total']
        this.records = data
      })
    },
    renderSpan(value, row, index) {
      const obj = {
        children: value,
        attrs: {},
      }

      obj.attrs.rowSpan = row.rowIndex

      return obj
    },
    getHeader() {
      return [
        {
          name: '时间',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
          props: {
            allowClear: true,
          },
        },
        {
          name: '二级商户号',
          key: 'subMerId',
          type: 'input',
        },
        {
          name: '商品名称',
          key: 'productName',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          fixed: 'left',
          width: 120,
          title: '二级商户',
          dataIndex: 'subMerId',
        },
        {
          title: '商户名称',
          dataIndex: 'subMerName',
        },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
        },
        {
          title: '商品名称',
          dataIndex: 'productName',
        },
        {
          title: '商品现有库存',
          dataIndex: 'stockNum',
        },
        {
          title: '总订单数',
          dataIndex: 'productOrderNum',
        },
        {
          title: '总订单金额',
          dataIndex: 'productTotalAmount',
        },
        {
          title: '总交易笔数',
          dataIndex: 'productSaleNum',
        },
        {
          title: '总交易金额',
          dataIndex: 'productSalePrice',
        },
        {
          title: '优惠笔数',
          dataIndex: 'productCouponNum',
        },
        {
          title: '优惠金额',
          dataIndex: 'productCouponAmount',
        },
        {
          title: '退款笔数',
          dataIndex: 'productRefundCount',
        },
        {
          title: '退款金额',
          dataIndex: 'productRefundAmount',
        },
        {
          title: '实收金额',
          dataIndex: 'productPayAmount',
          fixed: 'right',
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const data1 = { ...this.params }
            delete data1.current
            delete data1.size
            delete data1.pageSize
            let values
            Object.keys(data1).map((key, i) => {
              if (data1[key]) {
                if (!values) {
                  values = `?${key}=${data1[key]}`
                } else {
                  values = values + `&${key}=${data1[key]}`
                }
              }
            })

            downloadAction(`/api/merchantTransaction/exportReportPage${values || ''}`, '商户交易统计报表.xlsx')
          },
        },
      ]
    },
    selectRow(data, records) {
      this.shTypeList = records.map((d) => d.shType)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onChange={this.onChange}
        headerTypeData={this.headerTypeData}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
